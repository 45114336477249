@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GeneralSans;
  src: url(assets/fonts/GeneralSans-Bold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: GeneralSans;
  src: url(assets/fonts/GeneralSans-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: GeneralSans;
  src: url(assets/fonts/GeneralSans-Regular.woff2);
  font-weight: 400;
}

.font-ru {
  font-family: "Exo 2", sans-serif;
}

.font-general-sans {
  font-family: GeneralSans, sans-serif;
}
